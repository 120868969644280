<!-- 添加/编辑问题 -->
<template>
  <el-card>
    <div class="container">
      <div class="tabs">
        <div :class="activeName === '1' ? 'activeTab' : 'tab'" @click="tabClick('1')">问题详情</div>
        <div :class="activeName === '2' ? 'activeTab' : 'tab'" @click="tabClick('2')">标签管理</div>
        <div :class="activeName === '3' ? 'activeTab' : 'tab'" @click="tabClick('3')">
          半自动标签
        </div>
      </div>
      <el-form v-model="form" label-width="140px">
        <div class="sub" v-show="activeName === '1'">
          <div class="tittle">问题基本信息</div>
          <el-form-item label="问题标题">
            <el-input v-model="form.caption" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="题型">
            <el-radio-group v-model="form.optionTypes" @change="optionTypeChanged">
              <el-radio v-for="(i1, i2) in optionTypeList" :key="i2" :label="i1.label">
                {{ i1.name }}
              </el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="选项">
            <div class="item_box">
              <!-- 题型为“单选、多选题”时展示 -->
              <div v-if="form.optionTypes === '0' || form.optionTypes === '1'">
                <div v-for="(item, index) in form.optionList" :key="index">
                  <div class="row">
                    <span>选项{{ index + 1 }}</span>
                    <div class="icon_box" v-if="form.optionTypes == '0' || form.optionTypes == '1'">
                      <i class="el-icon-circle-plus" @click="addOptionItem"></i>
                      <i
                        class="el-icon-remove"
                        v-if="index > 0"
                        @click="deleteOptionItem(index)"
                      ></i>
                    </div>
                  </div>
                  <div class="row">
                    <el-input v-model="item.optionData" placeholder="请输入"></el-input>
                  </div>
                  <div class="row">
                    <div class="mar-right-10">是否需要填空</div>
                    <el-radio-group v-model="item.score">
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="2">否</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="row">
                    <el-input
                      v-model="item.noDeclarationReason"
                      type="textarea"
                      :rows="3"
                      placeholder="请输入选项默认回复"
                    ></el-input>
                  </div>
                </div>
              </div>
              <!-- 题型为“搜索选择”时展示 -->
              <div class="row" v-else-if="form.optionTypes === '14'">
                <el-radio-group v-model="form.optionList[0].score">
                  <el-radio v-for="(i1, i2) in searchRangeList" :key="i2" :label="i1.label">
                    {{ i1.name }}
                  </el-radio>
                </el-radio-group>
              </div>
              <!-- 题型为“填写题”时展示 -->
              <div v-else>
                <div class="row">
                  <el-radio-group v-model="form.optionList[0].score" @change="scoreChanged">
                    <el-radio v-for="(i1, i2) in typeList" :key="i2" :label="i1.label">
                      {{ i1.name }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <!-- 选中“数字”时展示 -->
                <div class="row" v-if="form.optionList[0].score === 3">
                  <!-- 需要后端加字段 -->
                  <el-radio-group v-model="form.optionList[0].type">
                    <el-radio v-for="(i1, i2) in numTypeList" :key="i2" :label="i1.label">
                      {{ i1.name }}
                    </el-radio>
                  </el-radio-group>
                </div>
                <!-- 选中“下拉框”时展示 -->
                <div class="row" v-else-if="form.optionList[0].score === 4">
                  <el-radio-group v-model="form.optionList[0].type">
                    <el-radio v-for="(i1, i2) in dropTypeList" :key="i2" :label="i1.label">
                      {{ i1.name }}
                    </el-radio>
                  </el-radio-group>
                </div>
              </div>
            </div>
            <!-- v-if="form.optionTypes != '2'" -->
          </el-form-item>
          <el-form-item label="问题说明">
            <el-input
              v-model="form.descriptionOfOptions"
              type="textarea"
              :rows="5"
              placeholder="请输入问题说明"
              maxlength="50"
              show-word-limit
            ></el-input>
          </el-form-item>
          <el-form-item label="问题说明展示">
            <div>
              <el-radio-group v-model="form.isDisplay">
                <el-radio :label="true">展示</el-radio>
                <el-radio :label="false">不展示</el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <!-- 编辑时才展示 -->
          <el-form-item label="关联工具" v-if="id">
            <span class="color1">{{ form.questionnaireNames }}</span>
          </el-form-item>
        </div>
        <div v-show="activeName === '2'">暂无内容</div>
        <div v-show="activeName === '3'">暂无内容</div>
      </el-form>
    </div>
    <div class="btn">
      <el-button type="primary" @click="save" class="btn1" :disabled="saveBtnDisabled"
        >保存</el-button
      >
      <el-button @click="cancel" plain>取消</el-button>
    </div>
  </el-card>
</template>

<script>
import { selectProblemById, addProblem, updateProblem } from '@/api/problem.js'
//添加问题的参数
const defaultForm = Object.freeze({
  questionnaireNames: null,
  caption: '', //问题名
  computationalFormula: '', //计算公式
  descriptionOfOptions: '', //问题说明
  evaluationType: null, //评估类型(1:企业数智化转型诊断评估,2:企业数智化转型政策申报)
  id: null, //主键：新增传null
  isDisplay: true, //问题说明是否展示
  optionTypes: '0', // 问题类型（0：单选，1：多选，2：填写）
  //选项集合
  optionList: [
    {
      id: null, // 选项id
      optionData: '', // 选项内容
      questionsId: null, // 所属问题
      score: 2, // 分值-填写限制填写类型
      type: '', // 怪异-问题类型(4、5、8)

      noDeclarationReason: '' // 选项说明
    }
  ],
  updateName: '', // 操作人
  updateTime: '', // 操作时间
  crossRegionDeclaration: true, // 跨地区申报
  city: '', // 城市
  province: '', // 省份
  country: '', // 国家
  // 政策id集合
  declarationId: [
    {
      id: null,
      name: ''
    }
  ],
  industryCategory: '', //行业类别
  industryCategoryList: [], //行业类别集合
  noDeclarationReason: '', //不可申报理由
  questionsType: '', //问题类型
  type: '' //问题大类
})
export default {
  name: 'addTrouble',
  components: {},
  data() {
    return {
      saveBtnDisabled: false, //禁用保存按钮，防止多次点击
      activeName: '1', //激活的页签
      //policyQueryInfo: { ...defaultPolicyQueryInfo },
      form: { ...defaultForm }, //表单填写的数据
      //问题类型集合
      // problemTypeList: [
      //   {
      //     label: '1',
      //     name: '企业基本信息',
      //     hasChildren: true,
      //     children: [
      //       {
      //         label: '1',
      //         name: '基本信息'
      //       }
      //     ]
      //   },
      //   {
      //     label: '2',
      //     name: '数智化经营',
      //     hasChildren: true,
      //     children: [
      //       {
      //         label: '1',
      //         name: '产品研发'
      //       },
      //       {
      //         label: '2',
      //         name: '工艺设计'
      //       },
      //       {
      //         label: '3',
      //         name: '计划与调度'
      //       },
      //       {
      //         label: '4',
      //         name: '生产作业'
      //       },
      //       {
      //         label: '5',
      //         name: '质量管控'
      //       },
      //       {
      //         label: '6',
      //         name: '采购管理'
      //       },
      //       {
      //         label: '7',
      //         name: '物流管理'
      //       },
      //       {
      //         label: '8',
      //         name: '仓储配送'
      //       },
      //       {
      //         label: '9',
      //         name: '营销管理'
      //       },
      //       {
      //         label: '10',
      //         name: '客户服务'
      //       },
      //       {
      //         label: '11',
      //         name: '产品服务'
      //       },
      //       {
      //         label: '12',
      //         name: '安全管理'
      //       },
      //       {
      //         label: '13',
      //         name: '环保管理'
      //       },
      //       {
      //         label: '14',
      //         name: '能源管理'
      //       },
      //       {
      //         label: '15',
      //         name: '集成应用'
      //       },
      //       {
      //         label: '16',
      //         name: '协同创新'
      //       },
      //       {
      //         label: '17',
      //         name: '运营管理'
      //       },
      //       {
      //         label: '18',
      //         name: '财务管理'
      //       },
      //       {
      //         label: '19',
      //         name: '资产管理'
      //       },
      //       {
      //         label: '20',
      //         name: '人力管理'
      //       }
      //     ]
      //   },
      //   {
      //     label: '3',
      //     name: '数智化基础',
      //     hasChildren: true,
      //     children: [
      //       {
      //         label: '1',
      //         name: '设备系统'
      //       },
      //       {
      //         label: '2',
      //         name: '网络安全'
      //       },
      //       {
      //         label: '3',
      //         name: '数据资源'
      //       }
      //     ]
      //   },
      //   {
      //     label: '4',
      //     name: '数智化管理',
      //     hasChildren: true,
      //     children: [
      //       {
      //         label: '1',
      //         name: '经营战略'
      //       },
      //       {
      //         label: '2',
      //         name: '管理机制'
      //       },
      //       {
      //         label: '3',
      //         name: '资金投入'
      //       },
      //       {
      //         label: '4',
      //         name: '人才建设'
      //       }
      //     ]
      //   },
      //   {
      //     label: '5',
      //     name: '数智化成效',
      //     hasChildren: true,
      //     children: [
      //       {
      //         label: '1',
      //         name: '价值效益'
      //       },
      //       {
      //         label: '2',
      //         name: '服务质量'
      //       },
      //       {
      //         label: '3',
      //         name: '生产效率'
      //       }
      //     ]
      //   },
      //   {
      //     label: '6',
      //     name: '专业化指标',
      //     hasChildren: false
      //   },
      //   {
      //     label: '7',
      //     name: '精细化指标',
      //     hasChildren: false
      //   },
      //   {
      //     label: '8',
      //     name: '特色化指标',
      //     hasChildren: false
      //   },
      //   {
      //     label: '9',
      //     name: '创新能力指标',
      //     hasChildren: false
      //   },
      //   {
      //     label: '10',
      //     name: '产业链配套指标',
      //     hasChildren: false
      //   },
      //   {
      //     label: '11',
      //     name: '数字化水平测试指标',
      //     hasChildren: false
      //   },
      //   {
      //     label: '12',
      //     name: '主导产品所属领域指标',
      //     hasChildren: false
      //   },
      //   {
      //     label: '13',
      //     name: '其他',
      //     hasChildren: false
      //   }
      // ],
      //二级问题类型集合
      problemTypeChildrenList: [],
      //题型集合
      optionTypeList: [
        {
          label: '0',
          name: '单选题'
        },
        {
          label: '1',
          name: '多选题'
        },
        {
          label: '2',
          name: '填写题'
        },
        {
          label: '14',
          name: '搜索选择'
        }
      ],
      //填写题限制填写类型集合
      typeList: [
        {
          label: 3,
          name: '数字'
        },
        {
          label: 2,
          name: '文字'
        },
        {
          label: 1,
          name: '百分比'
        },
        {
          label: 4,
          name: '下拉框'
        }
      ],
      //选中下拉框展示的选项类型
      dropTypeList: [
        {
          label: '3',
          name: '地区'
        },
        {
          label: '8',
          name: '行业'
        },
        {
          label: '4',
          name: '单个时间（年月日）'
        },
        {
          label: '5',
          name: '时间段（年月日）'
        }
      ],
      // 选中数字展示的选项类型
      numTypeList: [
        {
          label: '9',
          name: '手机号'
        },
        {
          label: '',
          name: '其他类型'
        }
      ],
      // 搜索范围列表
      searchRangeList: [
        {
          label: 1,
          name: '智参企业'
        }
      ]
    }
  },
  watch: {},
  computed: {
    echo() {
      let echo = this.$route.query.echo
      return echo
    },
    id() {
      let id = this.$route.query.id
      if (id) {
        return id
      } else {
        return null
      }
    }
  },
  created() {
    if (this.echo == '1') {
      // 编辑时
      this.search()
    } else {
      // 新增时
      this.form.optionList = [
        {
          id: null, //选项id
          optionData: '', //选项内容
          questionsId: this.id, //所属问题
          score: 2, //分值-填写限制填写类型
          type: '', // 怪异-问题类型(4、5、8)
          noDeclarationReason: null //选项说明
        }
      ]
    }
  },
  methods: {
    //查询问题详情
    async search() {
      const { data: res } = await selectProblemById({ questionsId: this.id })
      if (res.resultCode === 200) {
        this.form = res.data
        this.getQuestionnaireNames()
        if (!this.form.optionList[0].type) {
          this.form.optionList[0].type = ''
        }
      } else {
        this.$message.error('查询问题详情失败!')
      }
    },
    // 获取关联工具文字
    getQuestionnaireNames() {
      if (this.form.questionnaireNames) {
        if (this.form.questionnaireNames.length > 0) {
          let arr = []
          this.form.questionnaireNames.map((el) => {
            arr.push('#' + el)
          })
          this.form.questionnaireNames = arr.join('、')
        }
      }
    },
    //tab切换
    tabClick(val) {
      this.activeName = val
    },
    //题型切换
    optionTypeChanged(val) {
      this.form.optionList = []
      if (val == '2') {
        //题型为'填写题'时
        this.form.optionList = [
          {
            id: null, //选项id
            optionData: '选项', //选项内容  传默认值
            questionsId: this.id, //所属问题
            type: '', // 怪异-问题类型(4、5、8)
            score: 3, // 默认为数字
            noDeclarationReason: '选项' //选项说明 传默认值
          }
        ]
      } else if (val === '14') {
        // 题型为'搜索选择'时
        this.form.optionList = [
          {
            id: null, //选项id
            optionData: '选项', //选项内容  传默认值
            questionsId: this.id, //所属问题
            type: '', // 怪异-问题类型(4、5、8)
            score: 1, //分值-填写限制填写类型
            noDeclarationReason: '选项' //选项说明 传默认值
          }
        ]
      } else {
        // 题型为'单选、多选'时
        this.form.optionList = [
          {
            id: null, //选项id
            optionData: '', //选项内容  传默认值
            questionsId: this.id, //所属问题
            type: '', // 怪异-问题类型(4、5、8)
            score: 2, //分值-填写限制填写类型
            noDeclarationReason: '' //选项说明 传默认值
          }
        ]
      }
    },
    //选项类型改变  数字、百分比、下拉框
    scoreChanged(val) {
      if (val === 4) {
        this.form.optionList[0].type = '3'
      } else {
        this.form.optionList[0].type = ''
      }
    },
    //添加选项item
    addOptionItem() {
      this.form.optionList.push({
        id: null, // 选项id
        optionData: '', // 选项内容
        questionsId: this.id, // 所属问题
        score: 2, // 分值-填写限制填写类型
        type: '', // 怪异-问题类型(4、5、8)

        noDeclarationReason: '' // 选项说明
      })
    },
    //删除选项item
    deleteOptionItem(index) {
      this.form.optionList.splice(index, 1)
    },

    //保存
    async save() {
      this.saveBtnDisabled = true
      if (this.echo == '0') {
        const { data: res } = await addProblem(this.form)
        if (res.resultCode === 200) {
          this.saveBtnDisabled = false
          this.$message.success('新增成功!')
          this.$router.push({
            name: 'troubleManagement',
            path: '/diagnosis/troublemanagement'
          })
        } else {
          this.saveBtnDisabled = false
          this.$message.error('新增失败!')
        }
      } else {
        let params = { ...this.form }
        params.questionnaireNames = null
        const { data: res } = await updateProblem(params)
        if (res.resultCode === 200) {
          this.saveBtnDisabled = false
          this.$message.success('修改成功!')
          this.$router.push({
            name: 'troubleManagement',
            path: '/diagnosis/troublemanagement'
          })
        } else {
          this.saveBtnDisabled = false
          this.$message.error('修改失败!')
        }
      }
    },
    //取消
    cancel() {
      // this.$router.go(-1)
      this.$router.push({
        path: '/diagnosis/troublemanagement',
        query: {
          changeNum: true
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.mar-right-10 {
  margin-right: 10px;
}
.container {
  width: 100%;
  height: calc(100vh - 150px);
  padding: 20px;
  overflow-y: scroll;
  background-color: #fff;
  box-sizing: border-box;
  .tabs {
    display: flex;
    align-items: center;
    height: 40px;
    font-size: 20px;
    margin-bottom: 10px;
    border-bottom: #efefef 1px solid;
    .tab {
      height: 40px;
      color: #999;
      padding: 10px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .activeTab {
      height: 40px;
      color: #448aff;
      padding: 10px;
      border-bottom: #448aff 1.5px solid;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  box-sizing: border-box;
  border-top: #b6b4b4 1px solid;
}
.el-form-item .row {
  display: flex;
  margin-bottom: 10px;
}
.tittle {
  color: #146aff;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  font-family: 'PingFang SC';
  padding: 20px;
}
.el-form ::v-deep .el-form-item__label {
  color: #333;
  margin-right: 10px;
}
.el-form ::v-deep .el-form-item__content {
  display: flex;
}
.el-form-item ::v-deep .el-input {
  width: 560px;
}
.row ::v-deep .el-input {
  width: 480px;
}
.row1 ::v-deep .el-input {
  width: 270px;
}
.el-form-item ::v-deep .el-textarea {
  width: 560px;
}
.el-select ::v-deep .el-input {
  width: 200px;
}
.el-radio-group ::v-deep .el-radio {
  line-height: 40px;
}
.num_input ::v-deep .el-input__inner {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  box-sizing: border-box;
  color: #606266;
  display: inline-block;
  font-size: inherit;
  height: 40px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  width: 180px;
}
.check_square {
  ::v-deep .el-radio__inner {
    border-radius: 2px;
  }
  ::v-deep .el-radio__input.is-checked .el-radio__inner::after {
    content: '';
    width: 10px;
    height: 5px;
    border: 1px solid white;
    border-top: transparent;
    border-right: transparent;
    text-align: center;
    display: block;
    position: absolute;
    top: 2px;
    left: 0px;
    vertical-align: middle;
    transform: rotate(-45deg);
    border-radius: 0px;
    background: none;
  }
}
.el-form-item .item_list .item {
  display: flex;
  margin-bottom: 10px;
}
.icon_box {
  margin-left: auto;
  display: flex;
  align-items: center;
  height: 40px;
  i {
    cursor: pointer;
    width: 20px;
    height: 20px;
    font-size: 20px;
    color: #4e93fb;
    margin-left: 10px;
  }
}
.item_box {
  width: 560px;
  padding: 10px;
  border: #c4c4c4 1px dotted;
  box-sizing: border-box;
}
.item_box1 {
  width: 90%;
  padding: 10px;
  border: #c4c4c4 1px dotted;
  box-sizing: border-box;
}
.level2_tags {
  width: 80%;
  padding: 0 20px;
  background-color: #fafafa;
  box-sizing: border-box;
}
.color1 {
  color: #4e93fb;
}
</style>
